<template>
  <div class="activityTracking">
    <div class="header">
      <h2>Activity Tracking</h2>
      <div class="online-users">
        <div class="number">{{ currentOnline }}</div>
        <div class="description">Online Users</div>
      </div>

      <!-- <div class="actions">
        <button
          id="deleteUser"
          class="button"
          @click.prevent="selectAction($event)"
          :class="{
            active: modalMode == 'deleteUserModal' && UserModalActive == true,
          }"
        >
          Delete
        </button>

        <button
          id="editUser"
          class="button"
          @click.prevent="selectAction($event)"
          :class="{
            active: modalMode == 'editUserModal' && UserModalActive == true,
          }"
        >
          Edit
        </button>
        <button
          id="createUser"
          class="button"
          @click.prevent="selectAction($event)"
          :class="{
            active: modalMode == 'createUserModal' && UserModalActive == true,
          }"
        >
          Create
        </button>
        <button
          id="inviteUser"
          class="button"
          @click.prevent="selectAction($event)"
          :class="{
            active: modalMode == 'inviteUserModal' && UserModalActive == true,
          }"
        >
          Invite
        </button>
      </div> -->
    </div>

    <div class="modalBody">
      <div class="datawrapper">

                <ag-grid-vue
          style="width: 100%; height: 100%"
          class="ag-theme-balham-dark datatable"
          :columnDefs="curentUsersColumnDefs"
          :rowData="currentUsersRowData"
          :rowSelection="rowSelection"
          :defaultColDef="defaultColDef"
          @grid-ready="onGridReady"
          @grid-size-changed="sizeToFit"
          @selection-changed="onSelectionChanged"
          :sortingOrder="sortingOrder"
          :pagination="pagination"
          :paginationPageSize="paginationPageSize"
        >
        </ag-grid-vue>

        <ag-grid-vue
          style="width: 100%; height: 100%"
          class="ag-theme-balham-dark datatable"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :rowSelection="rowSelection"
          :defaultColDef="defaultColDef"
          @grid-ready="onGridReady"
          @grid-size-changed="sizeToFit"
          @selection-changed="onSelectionChanged"
          :sortingOrder="sortingOrder"
          :pagination="pagination"
          :paginationPageSize="paginationPageSize"
        >
        </ag-grid-vue>
      </div>
    </div>
  </div>
  <!-- <component
    :userId="uid"
    :is="modalMode"
    v-if="UserModalActive"
    v-on:close-modal="closeUserModal"
  /> -->
</template>

<script>
import { AgGridVue } from "ag-grid-vue3";
//import firebase from "firebase/compat/app";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { timestamp, auth, rtdb, db } from "../firebase/firebaseInit.js";
import {watch} from 'vue';

library.add(fas);

window.disableUserFormatter = function disableUserFormatter(params) {
  if (params.value == true) {
    return "Disabled";
  } else {
    return "Enabled";
  }
};

export default {
  
  data() {
    return {
      curentUsersColumnDefs: null,
      currentUsersRowData: [],
      columnDefs: null,
      rowData: [],
      rowSelection: null,
      gridApi: null,
      columnApi: null,
      defaultColDef: { resizable: true },
      modalMode: "createUserModal",
      UserModalActive: false,
      inviteCreateUserModalActive: false,
      sortingOrder: null,
      pagination: null,
      paginationPageSize: null,
      uid: null,
      currentOnline: 0,
      menumodal: null
    };
  },
  components: {
    AgGridVue,
  },
  methods: {
    selectAction(event) {
      if (
        (event.currentTarget.id !== "createUser" && this.uid == null) ||
        this.uid == ""
      ) {
        return;
      } else if (this.modalMode == event.currentTarget.id + "Modal") {
        this.UserModalActive = !this.UserModalActive;
      } else {
        this.modalMode = event.currentTarget.id + "Modal";
        this.UserModalActive = true;
      }
      console.log(this.modalMode);
    },
    onSelectionChanged() {
      const selectedRows = this.gridApi.getSelectedRows();
      this.uid = selectedRows.length === 1 ? selectedRows[0].uid : "";
      console.log(this.uid);
    },
    getAllUsers() {
      // db.collection("users")
      //   .get()
      //   .then((querySnapshot) => {
      //     querySnapshot.forEach((doc) => {
      //       //    console.log(doc.id, "=>", doc.data());
      //       this.rowData.push(doc.data());
      //       //    console.log(this.rowData);
      //     });
      //   });

        //     rtdb
        // .ref("realtimeTracking/")
        // .on("value", (snapshot) => {
        //   const data = snapshot.val();
        //   this.currentUsersRowData = [];
        //   Object.keys(data).forEach((key) => {
        //     if (data[key].active == true) {
        //     this.currentUsersRowData.push({
        //       userId: key,
        //       user: data[key].user,
        //       ipAddress: data[key].ipAddress,
        //       lastDisconnectTime: new Date(data[key].lastDisconnectTime),
        //       lastLoginTime: new Date(data[key].lastLoginTime),
        //     });
        //     }

        //   });
        //   this.sizeToFit;
        // });

      rtdb
        .ref("masterLog/")
        .limitToLast(100)
        .on("value", (snapshot) => {
          const data = snapshot.val();
          this.logs = [];
          Object.keys(data).forEach((key) => {
            let date = new Date(data[key].sendDate);
            this.rowData.push({
              id: key,
              userId: data[key].userId,
              type: data[key].type,
              user: data[key].user,
              date: date,
              streamRoomName: data[key].streamRoomName,
              streamRoom: data[key].streamRoom,
              projectName: data[key].projectName,
              ipAddress: data[key].ipAddress,
            });
          });
        });
    },
    sizeToFit() {
      this.gridApi.sizeColumnsToFit();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    openCreateUserModal() {
      this.modalMode = createUserModal;
      this.UserModalActive = true;
    },
    openInviteCreateUserModal() {
      this.modalMode = inviteCreateUserModal;
      this.UserModalActive = true;
    },
    openEditUserModal() {
      if (this.uid == null || this.uid == "") {
        return;
      } else {
        this.modalMode = editUserModal;
        this.UserModalActive = true;
      }
    },
    closeUserModal() {
      this.rowData = [];
      this.getAllUsers();
      this.UserModalActive = !this.UserModalActive;
    },
  },


  created() {
//
// CHECK RTDB for list of active users
// and add them to currentOnline count
//
    let rtTracking = rtdb.ref("realtimeTracking/");
    rtTracking
      .orderByChild("active")
      .equalTo(true)
      .on("value", (snapshot) => {
        const userdata = snapshot.val();
        const currentEpoch = new Date();
        this.currentOnline = 0;

        snapshot.forEach((childSnapshot) => {
          var key = childSnapshot.key;
          var childData = childSnapshot.val();

          if (childData.active == true) {
            this.currentOnline += 1;
          }
        });

          this.currentUsersRowData = [];
          Object.keys(userdata).forEach((key) => {

            // if (Math.floor(((currentEpoch - userdata[key].heartbeat)/1000)/60) > 5) {
            //   rtdb.ref("realtimeTracking/" + key).update({
            //     active: false
            //   })
            //   console.log("REMOVED ", userdata[key].user)
            // }

            if (userdata[key].active == true) {
            this.currentUsersRowData.push({
              userId: key,
              user: userdata[key].user,
              ipAddress: userdata[key].ipAddress,
              currentlyViewing: userdata[key].currentLocation,
              lastDisconnectTime: new Date(userdata[key].lastDisconnectTime),
              lastLoginTime: new Date(userdata[key].lastLoginTime),
              secondsSinceHeartbeat: (currentEpoch - userdata[key].heartbeat)/1000
            });
            }

          });

      });

  ///
  //
  ///

    this.rowData = [];
    this.getAllUsers();
    this.rowSelection = "single";
    this.sortingOrder = ["desc", "asc", null];
    // enables pagination in the grid
    this.pagination = true;
    // sets 10 rows per page (default is 100)
    this.paginationPageSize = 14;
    this.defaultColDef = {
      resizable: true,
    };
  },
  mounted() {
    this.curentUsersColumnDefs =  [{
        field: "user",
        filter: true,
        floatingFilter: true,
        minwidth: 100,
        sortable: true,
      },
      //       {
      //   field: "currentLocation",
      //   filter: true,
      //   floatingFilter: true,
      //   minwidth: 100,
      //   sortable: true,
      // },
      {
        field: "ipAddress",
        filter: true,
        floatingFilter: true,
        width: 120,
        sortable: true,
      },
            {
        field: "currentlyViewing",
        filter: true,
        floatingFilter: true,
        minwidth: 100,
        sortable: true,
      },
           {
        field: "lastLoginTime",
        sort: "desc",
        filter: true,
        floatingFilter: true,
        // minwidth: 120,
        sortable: true,
      },
      {
        field: "lastDisconnectTime",
        filter: true,
        floatingFilter: true,
        // minwidth: 120,
        sortable: true,
      },
            {
        field: "secondsSinceHeartbeat",
        filter: true,
        floatingFilter: true,
        minwidth: 100,
        sortable: true,
      },
 

      // { field: "uid", filter: true, floatingFilter: true },
    ];

    this.columnDefs = [
      {
        field: "user",
        filter: true,
        floatingFilter: true,
        minwidth: 100,
        sortable: true,
      },
      {
        field: "ipAddress",
        filter: true,
        floatingFilter: true,
        minwidth: 100,
        sortable: true,
      },
      {
        field: "date",
        filter: true,
        floatingFilter: true,
        minwidth: 120,
        sort: "desc",
        sortable: true,
      },
      {
        field: "type",
        filter: true,
        floatingFilter: true,
        width: 70,
        sortable: true,
      },
      {
        field: "streamRoomName",
        filter: true,
        floatingFilter: true,
        width: 120,
        sortable: true,
      },
      {
        field: "streamRoom",
        filter: true,
        floatingFilter: true,
        width: 120,
        sortable: true,
      },
      {
        field: "projectName",
        filter: true,
        floatingFilter: true,
        width: 120,
        sortable: true,
      },

      // { field: "uid", filter: true, floatingFilter: true },
    ];
  },
};
</script>

<style lang="scss" scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham-dark.css";

.activityTracking {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  flex-grow: 2;
  width: 100%;
  background-color: #262830;
  height: 90vh;
}

.header {
  background-color: RGB(47, 50, 60);
  height: 70px;
  display: flex;
  align-items: center;
  color: #e5ecff;
  font-size: 1.5em;
  font-weight: 700;
  padding-left: 25px;

  h2 {
    font-size: 24px;
    font-weight: 300;
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-items: center;
  margin-left: auto;
  margin-right: 30px;

  .button {
    position: relative;
    padding: 0px 15px;
    max-height: 37px;
    min-height: 33px;
    font-size: 12px;
    font-weight: 400;
    background-color: RGBA(68, 75, 90, 0.2);
    border: 1px solid #444b5a;
    color: inherit;
    cursor: pointer;
    min-width: 33px;
    margin-left: 10px;
  }

  #deleteUser {
    background-color: RGBA(233, 60, 45, 0.2);
    border: 1px solid RGB(233, 60, 45);
  }

  #deleteUser:hover {
    background-color: RGB(233, 60, 45, 0.8);
  }

  .button:hover {
    background-color: #505874;
  }
  .active {
    background-color: RGBA(239, 243, 255, 0.05) !important;
    border-color: RGB(239, 243, 255) !important;
  }
}

.modalBody {
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  padding: 15px 15px;
}

.datawrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  align-self: center;
  flex-direction: column;

  .datatable {
    width: calc(100% - 30px);
    height: calc(100% - 10px);
    border: 1px solid #000000;
  }
}

.online-users {
  margin-left: auto;
  margin-right: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #e5ecff;
  .description {
    font-size: 0.6em;
    font-weight: 300;
  }

  .number {
    font-weight: 500;
  }
}
</style>

<style lang="scss">
.ag-body-viewport {
   overflow-y: auto !important;
}
</style>
